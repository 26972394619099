<template>
  <div
    :class="{'__active': isMobileMenuOpen}"
    class="mobile-menu-content"
  >
    <div class="mobile-menu-content__content">
      <div class="mobile-menu-content__header">
        <div
          class="mobile-menu"
          @click="closeMenu"
        >
          <img
            src="~/assets/img/svg/header/close.svg"
            alt="close"
          >
        </div>
        <div class="search-input__header-mob">
          <SearchInput
            is-header
            @close-search="closeMenu"
          >
            <template #search-image>
              <img
                src="~/assets/img/svg/header/search.svg"
                alt="search icon"
              >
            </template>
          </searchinput>
        </div>
      </div>

      <div class="mobile-menu-content__body">
        <div class="mobile-menu-content__page-collection">
          <div
            v-for="(item,idx) in catalogTree?.[0]?.items"
            :key="`mobile-menu-content__page-collection-item-${idx}`"
            class="mobile-menu-content__page-collection-item"
          >
            <span class="mobile-menu-content__page-collection-item-name">{{ item.name }}</span>

            <img
              class="mobile-menu-content__page-collection-item-icon"
              src="~/assets/img/svg/header/arrowpright.svg"
              alt="arrow"
            >

            <nuxt-link
              class="mobile-menu-content__page-collection-item-link"
              :to="item.url"
              @click.native="closeMenu"
            />
          </div>
        </div>
        <div
          v-for="(menuItem, idx) in menu"
          :key="`mobile-menu-content__page-cms-${idx}`"
          class="mobile-menu-content__page-cms"
        >
          <nuxt-link
            :to="`/${menuItem.path}`"
            @click.native="closeMenu"
          >
            {{ menuItem.title }}
          </nuxt-link>
        </div>
        <div class="mobile-menu-content__work-time">
          <span>Время работы</span>
          10:00 - 20:00
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">

import type { ICategory } from '#sitis/internal/controllers/categories/models/Category';
import { useMobileMenu } from '~/composables/useMobileMenu';
import type { IContentMenuItem } from '#sitis/internal/controllers/cms/models/ContentMenu';

const { catalogTree } = defineProps<{
catalogTree:ICategory[];
menu:	IContentMenuItem[];
}>();
const { isMobileMenuOpen, onCloseMobileMenu } = useMobileMenu();
const closeMenu = () => {
  document.body.style.overflow = '';
  document.body.classList.remove('mobile');
  onCloseMobileMenu();
};
</script>

<style lang="scss">
.mobile-menu-content {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100%;
  background: #fff;
  left: 0;
  top: 0;
  z-index: 20;
  padding: 14px 8px;
  box-sizing: border-box;

  &.__active {
    display: block;
  }
}

.mobile-menu-content__header {
  display: flex;
  margin-bottom: 16px;
  align-items: center;

  & .mobile-menu {
    background: #f2f2f2;
    margin-right: 16px;
    min-width: 48px;
    min-height: 48px;
    display: flex;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;

  }

}

.mobile-menu-content__body {
  overflow: auto;
  height: 80vh;
}

.mobile-menu-content__page-collection {
  margin-bottom: 35px;
}

.mobile-menu-content__page-collection-item {
  display: flex;
  align-items: center;
  padding: 12px;
  position: relative;
  background: red;
  margin-bottom: 8px;
}

.mobile-menu-content__page-collection-item-icon {
  margin-left: auto;
}

.mobile-menu-content__page-collection-item-name {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #fff;
}

.mobile-menu-content__page-collection-item-link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.mobile-menu-content__page-cms {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;

  & a {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1px;
    color: #000;
    text-decoration: none;
    margin-bottom: 14px;
  }
}

.mobile-menu-content__work-time {
  margin-top: 25px;
  font-size: 20px;

  & span {
    font-weight: 700;
  }
}

.mobile > .extra-container{
  top: 75px;
}

.search-input__header-mob{
  width: 100%;

  & .search-input__input{
    background: #f2f2f2;
    border-radius: 100px;

    & .search-input__img{
      display: flex;
    }
  }
}
</style>
